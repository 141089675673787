/*
 * File: CandleMaking.jsx
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: November 24, 2023 at 11:35 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import validator from 'validator';

// Utilities
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStateLocale } from '../../../utilities/locale';
import { toastError } from '../../../utilities/toaster';

// Services
import { postContactSubmission } from '../../../services/contact';

// Components
import {
	Meta, AppNavigation, Typography, Emoji, TextInput, TextArea, ConfettiBurst, Checkbox, IconContainer
} from '../../../components';

// Styles
import * as S from './CandleMaking.styles';


/**
 * Constants
 */

const INTERESTED_OPTION_CANDLE_MAKING_HOSTING = {
	label: 'Interested in Hosting Candle Making Classes',
	value: 'Interested in Hosting Candle Making Classes',
	hash: 'candlemaking'
};


/**
 * Component
 */

const CandleMaking = ({ meta, locale }) => {

	// Create state handlers
	const [nameError, setNameError] = useState(null);
	const [emailError, setEmailError] = useState(null);
	const [messageError, setMessageError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [showContactForm, setShowContactForm] = useState(true);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [fireConfetti, setFireConfetti] = useState(false);
	const [inputValues, setInputValues] = useState({
		question_input: '',
		_magic: '',
		_magic2: ''
	});

	// Get actions from hooks
	const { executeRecaptcha } = useGoogleReCaptcha();

	// Get current locale from hook
	const clientLocale = useSelector((state) => state.locale.value);
	const stateLocale = createStateLocale(clientLocale, locale);

	// Get current UI mode from hook
	const uiMode = useSelector((state) => state.ui.value);

	// Create refs
	const confettiTargetRef = useRef();

	// Handle submit contact form function
	const handleSubmitContactForm = async () => {

		// Get parameters
		const {
			name, email, organization, message, marketingAllowed, question_input: questionInput, _magic, _magic2
		} = inputValues;

		// Validate parameters
		if (!name || validator.isEmpty(name, { ignore_whitespace: true })) {
			setNameError({ message: 'Please enter your name' });
			return;
		}
		if (!email || !validator.isEmail(email)) {
			setEmailError({ message: 'Please enter your email address' });
			return;
		}
		if (!message || validator.isEmpty(message, { ignore_whitespace: true })) {
			setMessageError({ message: 'Please enter a few details about your request' });
			return;
		}

		// Set loading state
		setIsLoading(true);

		// Get reCAPTCHA token
		let recaptchaToken = null;
		try { recaptchaToken = await executeRecaptcha('submit_candle_making_form'); } catch (e) {}

		// Submit contact form
		postContactSubmission({
			name,
			email,
			interested: INTERESTED_OPTION_CANDLE_MAKING_HOSTING.value,
			organization,
			message,
			marketingAllowed,
			recaptchaToken,
			question_input: questionInput,
			_magic,
			_magic2
		}).then(() => {

			// Hide form
			setShowContactForm(false);
			setTimeout(() => {

				// Clear inputs
				setInputValues({});

				// Show confirmation
				setShowConfirmation(true);
				setTimeout(() => {
					setFireConfetti(true);
				}, 100);

			}, 300);

		}).catch(({ response }) => {

			// Set loading state
			setIsLoading(false);

			// Show error message
			if (response?.data?.message) {
				toastError(uiMode, response?.data?.message);
			} else {
				toastError(uiMode, 'Whoops. We\'re having trouble sending your message. Please try again.');
			}
		});
	};

	// Handle on input change action
	const handleOnChange = (event) => {
		const {
			name, value, checked, type
		} = event.target;
		if (type === 'checkbox') {
			setInputValues({ ...inputValues, [name]: checked });
		} else {
			setInputValues({ ...inputValues, [name]: value });
		}
	};

	// Handle component initialization
	useEffect(() => {}, []);

	// Render component
	return (
		<>
			{/* Meta */}
			<Meta meta={meta} locale={stateLocale} />

			{/* Component Content */}
			<AppNavigation noContentMax hidePadding>
				<S.Wrapper>

					{/* Hero Content */}
					<S.HeroSection>
						<S.ContentPanel>
							<IconContainer>
								<FontAwesomeIcon icon={['fasl', 'fire']} />
							</IconContainer>
							<Typography tag="h1" weight="bold">
								Host a Candle Making Workshop with 700 Rivers
							</Typography>
							<Typography tag="p" weight="light">
								Looking to host an engaging, unique event in your space? 700 Rivers Candle Making Workshops are perfect for team-building activities. Our team will provide the materials, tools, and step-by-step guidance to make sure everyone has a great time.
							</Typography>
							<Typography tag="h4" weight="bold" style={{ marginTop: '25px' }}>
								See what&apos;s included
								<FontAwesomeIcon icon={['fass', 'arrow-down-right']} style={{ marginLeft: '8px', fontSize: '1.4rem' }} />
							</Typography>
						</S.ContentPanel>
						<S.MediaPanel>
							<S.HeroImage
								src={`${process.env.CDN_URL}/public/images/bonfire-nights-candle-display.jpg`}
								alt="A display of 700 Rivers' Bonfire Nights candles with charcoal and wooden wicks"
							/>
						</S.MediaPanel>
					</S.HeroSection>

					{/* Content Padding */}
					<S.ContentPadding left right dynamic isLarge>

						{/* Features Section */}
						<S.ContentSection>

							{/* Title */}
							<S.TitleSection $left>
								<Typography tag="h2" weight="bold">Included in a 700 Rivers Workshop</Typography>
							</S.TitleSection>

							{/* Features */}
							<S.FeaturesGrid>

								{/* Feature #1 */}
								<S.FeatureBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fasl', 'fire']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Candle Crafting
									</Typography>
									<Typography tag="p" weight="light">
										Learn the step-by-step candle making process by reviewing the tools needed, receiving safety instructions, get an overview of the clean ingredients you’ll be using, and get hands-on instructions to set up, design, and pour your own candle. We provide all the materials you’ll need, so you can focus on creating your masterpiece.
									</Typography>
								</S.FeatureBlock>

								{/* Feature #2 */}
								<S.FeatureBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fasl', 'heart']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Personalized Intentions
									</Typography>
									<Typography tag="p" weight="light">
										Set an intention around the candle that is enhanced with an engraved wooden wick. You can choose a wick that says “JOY, PEACE, CALM, etc” to create a reminder of what you choose to bring into your life each time you light your candle.
									</Typography>
								</S.FeatureBlock>

								{/* Feature #3 */}
								<S.FeatureBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fasl', 'mortar-pestle']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Scent Exploration
									</Typography>
									<Typography tag="p" weight="light">
										Learn the scent development process to create a unique signature blend. Whether you prefer floral, fruity, spicy, or earthy notes, we have a wide range of clean, high-quality fragrance oil options.
									</Typography>
								</S.FeatureBlock>

								{/* Feature #4 */}
								<S.FeatureBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fal', 'earth-asia']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Social Impact
									</Typography>
									<Typography tag="p" weight="light">
										Learn the history and mission of 700 Rivers, along with the gift-back program to fund scholarships for girls in Bangladesh to continue their education. This give-back program is funded by the purchase of each candle and your workshop ticket.
									</Typography>
								</S.FeatureBlock>

								{/* Feature #5 */}
								<S.FeatureBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fal', 'flower-tulip']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Glow and Grow
									</Typography>
									<Typography tag="p" weight="light">
										End your candle making experience by adding a wildflower seed embedded dust cover on top of your candle. This cover turns your candle in 2 products in 1. After burning your candle, plant the dust cover in the same vessel and then watch beautiful wildflowers bloom in your home – a living memento of your workshop experience.
									</Typography>
								</S.FeatureBlock>

								{/* Feature #6 */}
								<S.FeatureBlock>
									<IconContainer>
										<FontAwesomeIcon icon={['fasl', 'house-chimney']} />
									</IconContainer>
									<Typography tag="h5" weight="bold">
										Take Home Your Masterpiece
									</Typography>
									<Typography tag="p" weight="light">
										After the workshop, you’ll leave with your personally crafted 12 oz candle, a unique creation that embodies your signature scent and style.
									</Typography>
								</S.FeatureBlock>
							</S.FeaturesGrid>

						</S.ContentSection>

						{/* Contact Form Section */}
						<S.ContentSection>
							<S.ContactFormContainer>

								{/* Content Pane */}
								<S.ContentPane>
									<S.ContentContainer>
										<IconContainer>
											<FontAwesomeIcon icon={['fal', 'hand-wave']} />
										</IconContainer>
										<Typography tag="h1" weight="bold">Interested in learning more?</Typography>
										<Typography tag="p" weight="light">
											Hosting a 700 Rivers Candle-Making Workshop is a perfect team-building activity, or a great way to engage customers. Send us a few details about yourself and we&apos;ll follow up with more information!
										</Typography>
									</S.ContentContainer>
								</S.ContentPane>

								{/* Form Pane */}
								<S.FormPane>

									{/* Contact Form */}
									<S.Form className="animate" isVisible={showContactForm}>

										{/* Inputs */}
										<TextInput
											label="Name"
											name="name"
											type="text"
											error={nameError}
											value={inputValues.name || ''}
											autoComplete="name"
											containerClassName="formInput"
											onFocus={() => { setNameError(null); }}
											onKeyUp={() => { setNameError(null); }}
											onBlur={() => { setNameError(null); }}
											onChange={handleOnChange}
											smartField
										/>
										<TextInput
											label="Email Address"
											name="email"
											type="text"
											error={emailError}
											value={inputValues.email || ''}
											autoComplete="email"
											containerClassName="formInput"
											onFocus={() => { setEmailError(null); }}
											onKeyUp={() => { setEmailError(null); }}
											onBlur={() => { setEmailError(null); }}
											onChange={handleOnChange}
											smartField
										/>
										<TextInput
											label="Organization (optional)"
											name="organization"
											type="text"
											value={inputValues.organization || ''}
											autoComplete="organization"
											containerClassName="formInput"
											onChange={handleOnChange}
											smartField
										/>
										<TextArea
											label="Any details to share?"
											name="message"
											type="text"
											rows={4}
											error={messageError}
											value={inputValues.message || ''}
											containerClassName="formInput"
											onFocus={() => { setMessageError(null); }}
											onKeyUp={() => { setMessageError(null); }}
											onBlur={() => { setMessageError(null); }}
											onChange={handleOnChange}
											smartField
										/>

										{/* Question Input */}
										<input
											id="question_input"
											name="question_input"
											type="text"
											value={inputValues.question_input || ''}
											onChange={handleOnChange}
											className="question-input"
										/>

										{/* Honeypots */}
										<input
											type="text"
											name="_magic"
											value={inputValues._magic || ''}
											onChange={handleOnChange}
											className="gotcha-field"
										/>
										<input
											type="text"
											name="_magic2"
											value={inputValues._magic2 || ''}
											onChange={handleOnChange}
											style={{ display: 'none' }}
										/>

										{/* Checkboxes */}
										<S.CheckboxContainer>
											<S.CheckboxRow>
												<Checkbox
													name="marketingAllowed"
													value={inputValues.marketingAllowed || false}
													onChange={handleOnChange}
												/>
												<Typography variation="2" weight="regular">
													Yes! I would like to receive updates, special offers, and other information from 700 Rivers
												</Typography>
											</S.CheckboxRow>
										</S.CheckboxContainer>

										{/* Continue Action */}
										<S.ActionButton disabled={isLoading} isLoading={isLoading} onClick={() => { handleSubmitContactForm(); }} size="large" className="floatShadow">Send Message</S.ActionButton>
									</S.Form>

									{/* Confirmation Content */}
									<S.ConfirmationContainer className="animate" isVisible={showConfirmation}>

										{/* Content Pane */}
										<S.ConfirmationPane>

											{/* Content */}
											<S.ConfirmationContent>
												<Emoji symbol="🎉" size={3.5} label="Celebration" />
												<S.ConfettiTarget ref={confettiTargetRef} />
												<Typography tag="h3" weight="bold">Thanks for reaching out!</Typography>
												<Typography tag="p" variation="1">One of our team members will follow up shortly. In the meantime, check out our blog for other helpful topics.</Typography>
											</S.ConfirmationContent>

											{/* Content Background */}
											<S.ConfirmationBackground />

										</S.ConfirmationPane>
									</S.ConfirmationContainer>
								</S.FormPane>
							</S.ContactFormContainer>
						</S.ContentSection>

						{/* CTA Section */}
						<S.ContentSection>

							{/* Title */}
							<S.TitleSection $left>
								<Typography tag="h2" weight="bold">More from 700 Rivers</Typography>
							</S.TitleSection>

							{/* Card Container */}
							<S.CardContainer>

								{/* Seasonal Subscription */}
								<S.CardLink to="/seasonal-subscription">
									<S.OptionCard className="animate">
										<FontAwesomeIcon icon={['fasl', 'party-horn']} />
										<Typography tag="h4" weight="semibold">The ultimate treat yourself</Typography>
										<Typography tag="p" weight="light">Subscribe to get your seasonal favorites delivered every three months</Typography>
										<S.ActionContainer>
											<Typography>Explore the seasonal subscription</Typography>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.ActionContainer>
									</S.OptionCard>
								</S.CardLink>

								{/* Wholesale */}
								<S.CardLink to="/wholesale">
									<S.OptionCard className="animate">
										<FontAwesomeIcon icon={['fasl', 'tag']} />
										<Typography tag="h4" weight="semibold">700 Rivers Wholesale</Typography>
										<Typography tag="p" weight="light">Our team is happy to put together the perfect package for your customers</Typography>
										<S.ActionContainer>
											<Typography>Explore wholesale opportunities</Typography>
											<FontAwesomeIcon icon={['fass', 'arrow-right']} />
										</S.ActionContainer>
									</S.OptionCard>
								</S.CardLink>
							</S.CardContainer>
						</S.ContentSection>
					</S.ContentPadding>
				</S.Wrapper>
			</AppNavigation>

			{/* Confetti */}
			{fireConfetti && (
				<ConfettiBurst
					fireAway={fireConfetti}
					targetRef={confettiTargetRef}
					force={0.4}
					duration={3000}
					particleCount={80}
				/>
			)}
		</>
	);
};


/**
 * Configuration
 */

CandleMaking.propTypes = {
	meta: PropTypes.shape(),
	locale: PropTypes.shape(),
};
CandleMaking.defaultProps = {
	meta: {},
	locale: {}
};


/**
 * Exports
 */

export default CandleMaking;
