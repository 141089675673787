/*
 * File: CandleMaking.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: October 27, 2023 at 9:56 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import {
	Button, Card, LocaleLink, Padding
} from '../../../components';

// Constants
import {
	largeBreakpoint, mediumBreakpoint, mobileBreakpoint, xLargeBreakpoint
} from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const HeroSection = styled.div`
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;
	padding-bottom: 50px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const ContentSection = styled.div`
	padding: 80px 0px 80px;
	margin: 0px 0px;
`;

export const ContentPanel = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 50%;
	text-align: left;
	margin: 0px auto;
	width: 88%;
	padding: 100px 0px;

	* {
		text-align: left;
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: 4% 8rem;
	}
	h4 {
		margin: 12px 0 0px;
	}
	p {
		margin: 20px 0 0px;
		font-size: 1.2rem;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const MediaPanel = styled.div`
	position: relative;
	flex: 50%;
`;

export const HeroImage = styled.img`
	width: 100%;
`;

export const ContactFormContainer = styled.div`
	display: flex;
	padding: 0px 0px 50px;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
	gap: 100px;
	flex-direction: column;

	@media only screen and (min-width: ${largeBreakpoint}em) {
		flex-direction: row;
	}
	.gotcha-field {
		display: none !important;
	}
	.question-input {
		overflow: visible;
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 0 !important;
		width: 0;
		z-index: -1;
		padding: 1px !important;
	}
`;

export const ContentPane = styled.div`
	margin-top: 50px;

	@media only screen and (min-width: ${largeBreakpoint}em) {
		margin-top: 0px;
	}
`;

export const FormPane = styled.div`
	width: 100%;
	max-width: 440px;
	flex-shrink: 0;
	position: relative;
`;

export const ContentContainer = styled.div`
	text-align: left;

	h1 {
		text-align: left;
		color: ${({ theme }) => theme.primaryText};
		font-family: 'Unna', serif;
	}
	p {
		text-align: left;
		margin: 20px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const Form = styled.form`
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};

	button {
		width: 100%;
	}
	.formInput {
		margin: 20px 0 0;
	}
`;

export const ActionButton = styled(Button)`
	margin: 20px auto 0px;
`;

export const CheckboxRow = styled.div`
	display: flex;
	margin: 20px 0 0 0;

	span {
		flex-grow: 1;
		text-align: left;
		margin: 0 0 0 12px;
		color: ${({ theme }) => theme.primaryText};
		position: relative;
		top: -1px;

		a,
		a:link,
		a:visited {
			color: inherit;
		}
	}
`;

export const CheckboxContainer = styled.div`
	margin: 25px 0px 35px 0px;
`;

export const ConfirmationContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
	pointer-events: ${({ isVisible }) => (isVisible ? 'all' : 'none')};
`;

export const ConfirmationPane = styled.div`
	text-align: center;
	border-radius: 10px;
	padding: 40px 20px;
	position: relative;
	overflow: hidden;

	h3 {
		color: ${({ theme }) => theme.primaryText};
	}
	p {
		margin: 10px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const ConfirmationBackground = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
	z-index: 1;
`;

export const ConfirmationContent = styled.div`
	position: relative;
	z-index: 2;
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 20px;
	left: 50%;
`;

export const ContentPadding = styled(Padding)`
	flex-direction: column;
`;

export const CardContainer = styled.div`
	display: flex;
	gap: 20px;
	margin: 0px 0px 0px;
	flex-direction: column;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const CardLink = styled(LocaleLink)`
	text-decoration: none;
	flex-grow: 1;
    flex-basis: 0;
`;

export const OptionCard = styled(Card)`
	background-color: ${({ theme }) => theme.secondaryBackground};
	text-align: center;
	padding: 30px;

	svg {
		font-size: 2.5rem;
		margin: 0px 0px 15px;
	}
	h4 {
		margin: 0px 0px 10px;
	}
	&:hover {
		transform: scale(1.02);
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px auto 0px;
	gap: 7px;

	svg {
		font-size: 0.85rem;
		margin: 0px;
	}
`;

export const TitleSection = styled.div`
	margin: 0px 0px 30px;
	width: 100%;

	h2 {
		font-family: 'Unna', serif;
		text-align: ${({ $left }) => ($left ? 'left' : 'center')};
	}
`;

export const FeaturesGrid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 34px;
	margin: 50px 0px 0px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${xLargeBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const FeatureBlock = styled.div`
	p {
		color: ${({ theme }) => theme.secondaryText};
		margin: 6px 0px 0px;
	}
	* {
		text-align: left;
	}
`;
