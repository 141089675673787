/*
 * File: GiftCardPreviewModal.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on February 7, 2022 at 1:53 PM
 * Copyright © 2023 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: November 24, 2023 at 3:43 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Styles
import { ColorValues } from '../../styles/colors';


/**
 * Styles
 */

export const ModalInner = styled.div`
	padding: 0px 0px 30px;
	text-align: center;
	width: 100%;

	.subtitle {
		margin: 5px 0 35px;
		padding: 0 20px;
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	gap: 10px;
	flex-direction: column;
	margin: 4px 0px 10px 0px;

	button {
		flex-grow: 1;
   	 	flex-basis: 0;
		padding-top: 0.85rem;
		padding-bottom: 0.85rem;
	}
	a,
	a:link,
	a:visited {
		text-decoration: none;
		color: ${({ theme }) => theme.brandPrimaryBase};
	}
`;

export const LogoContainer = styled.div`
	position: relative;
	margin: 0px 0px 15px 0px;
`;

export const LogoImage = styled.img`
	width: 90px;
	height: auto;
`;

export const InputContainer = styled.div`
	margin: 0px 0px 25px 0px;
`;

export const DisclaimerContainer = styled.div`
	p {
		color: ${({ theme }) => theme.secondaryText};
		font-size: 0.6rem !important;
	}
`;

export const ConfettiTarget = styled.div`
	position: absolute;
	top: 24px;
	right: 50%;
`;

export const GiftCardContainer = styled.div`
`;

export const RewardGiftCard = styled.div`
	padding: 20px 20px 25px;
	min-height: 200px;
	border: 1px solid ${({ theme }) => theme.brandPrimaryBase};
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h3 {
		font-family: 'Unna', serif;
		color: ${({ theme }) => theme.brandPrimaryBase};
		margin-top: -4px;
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
		margin: 0px 0px 20px;
	}
`;

export const LogoMark = styled.img`
	width: 40px;
	height: 40px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
`;

export const CodeContainer = styled.div`
	display: flex;
	gap: 6px;
`;

export const Code = styled.div`
	background-color: ${({ theme }) => theme.brandPrimaryBase};
	padding: 14px 20px;
	margin: 10px 0px 0px;

	* {
		color: ${ColorValues.white};
	}
`;

export const Value = styled.div`
	border: 1px solid ${ColorValues.neutralText};
	padding: 14px 20px;
	margin: 10px 0px 0px;

	* {
		color: ${ColorValues.neutralText};
	}
`;

export const ShareContainer = styled.div`
	padding: 40px 0px;

	button {
		margin: 20px 0px 0px;
		width: 100%;
	}
`;
