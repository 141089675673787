/*
 * File: Presskit.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: September 25, 2023 at 12:54 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Styles
import { ColorValues } from '../../../styles/colors';

// Constants
import {
	mediumBreakpoint, mobileBreakpoint
} from '../../../styles/constants';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const HeroSection = styled.div`
	padding: 100px 0px 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	h1 {
		margin-bottom: 20px;
	}
`;

export const HeroTitleContent = styled.div`
	max-width: 800px;
	width: 100%;
`;

export const HeroSubtitleContent = styled.div`
	max-width: 500px;
	width: 100%;
`;

export const PresskitSection = styled.div`
	display: flex;
	flex-direction: column;
	padding: 50px 0px 80px;
	gap: 50px;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
		gap: 100px;
	}
`;

export const DescriptionPanel = styled.div`
	width: 100%;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		width: 34%;
	}
	h2 {
		margin-bottom: 24px;
	}
	p {
		margin-bottom: 16px;
	}
	* {
		text-align: left;
	}
`;

export const ContentPanel = styled.div`
	width: 100%;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		width: 66%;
	}
`;

export const DownloadLink = styled.a`
	text-decoration: none;
	display: inline-flex;
	gap: 6px;
	align-items: center;
	margin-top: 12px;

	&:hover {
		text-decoration: underline;
	}
`;

export const LogoGrid = styled.div`
	width: 100%;
	display: grid;
	gap: 55px;
	grid-template-columns: repeat(1, 1fr);

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const LogoColumn = styled.div``;

export const LogoContainer = styled.div`
	background-color: ${({ theme }) => theme.cardBackground};
	width: 100%;
	border-radius: 4px;
	margin-bottom: 12px;
	height: 170px;
	text-align: center;
	display: flex;
    align-items: center;
    justify-content: center;

	${({ $vertical }) => ($vertical ? css`
		padding: 40px 45px;

		img {
			height: 100%;
		}
	` : css`
		padding: 60px 45px;

		img {
			height: auto;
		}
	`)};
`;

export const Logo = styled.img`
    max-width: 100%;
`;

export const DescriptionContainer = styled.div`
	display: flex;
	align-items: start;
`;

export const Description = styled.div`
	flex-grow: 1;

	* {
		text-align: left;
	}
	h3 {
		font-size: 1.1rem;
	}
`;

export const DownloadContainer = styled.div`
	display: flex;
	gap: 10px;
	padding-top: 6px;
`;

export const InlineDownloadLink = styled.a`
	font-size: 0.7rem;
`;

export const BadExamplesContainer = styled.div`
	margin-top: 30px;

	p {
		display: flex;
		gap: 10px;
		align-items: center;

		svg {
			font-size: 1.4rem;
			color: ${({ theme }) => theme.statusDangerBase};
		}
	}
`;

export const BadExamplesGrid = styled.div`
	width: 100%;
	display: grid;
	gap: 30px;
	grid-template-columns: repeat(2, 1fr);

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const BadExample = styled.div`
	background-color: ${({ theme }) => theme.cardBackground};
	border-radius: 4px;
	position: relative;

	svg {
		font-size: 1.4rem;
		color: ${({ theme }) => theme.statusDangerBase};
		position: absolute;
		top: 10px;
		right: 10px;
	}
	img {
		max-width: 100%;
    	height: auto;
	}
`;

export const ColorGrid = styled.div`
	width: 100%;
	display: grid;
	gap: 30px;
	grid-template-columns: repeat(2, 1fr);

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const BrandColor = styled.div`
	* {
		text-align: left;
	}
`;

export const ColorSwatch = styled.div`
	width: 120px;
    height: 120px;
    border-radius: 50%;
    margin-bottom: 20px;
    border: 1px solid ${({ theme }) => theme.layoutBorder};

	&.sunriseLight {
		background-color: ${ColorValues.brandPrimaryBase};
	}
	&.sunriseDark {
		background-color: ${ColorValues.brandPrimaryDark};
	}
	&.paleFiber {
		background-color: ${ColorValues.brandBeigeLighter};
	}
	&.twilightDark {
		background-color: ${ColorValues.dark};
	}
	&.twilightWhite {
		background-color: ${ColorValues.white};
	}
`;

export const ColorCodesContainer = styled.div`
	display: grid;
    grid-template-columns: 50px 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
	margin-top: 22px;
    margin-bottom: 15px;
`;

export const ColorKey = styled.div`
	display: flex;
    align-items: center;
	color: ${({ theme }) => theme.secondaryText};
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
`;

export const ColorCode = styled.div`
	span {
		border-bottom: 1px solid ${({ theme }) => theme.layoutBorder};
		font-size: 13px;
		position: relative;
		top: -2px;
		font-weight: 300;
	}
`;

export const FontGrid = styled.div`
	width: 100%;
	display: grid;
	gap: 80px;
	grid-template-columns: repeat(1, 1fr);

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const BrandFont = styled.div`
	* {
		text-align: left;
	}
`;

export const FontHolder = styled.div`
	margin-bottom: 22px;
	min-height: 175px;

	&.unna {
		h2 {
			font-family: 'Unna', serif;
		}
	}
	h2 {
		font-size: 2.8rem;
	}
`;

export const ColorDistributionContainer = styled.div`
	width: 100%;
    height: 40px;
    border: 1px solid ${({ theme }) => theme.layoutBorder};
    border-radius: 4px;
    font-size: 0px !important;
	overflow: hidden;
`;

export const DistributionItem = styled.div`
	height: 100%;
    display: inline-block;

	&.sunriseLight {
		background-color: ${ColorValues.brandPrimaryBase};
		width: 25%;
	}
	&.sunriseDark {
		background-color: ${ColorValues.brandPrimaryDark};
		width: 5%;
	}
	&.twilightDark {
		background-color: ${ColorValues.dark};
		width: 20%;
	}
	&.twilightWhite {
		background-color: ${ColorValues.white};
		width: 25%;
	}
	&.paleFiber {
		background-color: ${ColorValues.brandBeigeLighter};
		width: 25%;
	}
`;

export const ImageGrid = styled.div`
	width: 100%;
	display: grid;
	gap: 40px;
	grid-template-columns: repeat(1, 1fr);

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

export const ImageContainer = styled.div``;

export const PressImage = styled.img`
	width: 100%;
	border-radius: 4px;
	margin-bottom: 6px;
`;
