/*
 * File: index.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on July 10, 2022 at 7:40 PM
 * Copyright © 2022 700 Rivers, LLC. All rights reserved.
 *
 * Last Modified: April 2, 2023 at 9:29 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Utilities
import api from '../api';


/**
 * Services
 */

export const postContactSubmission = (data) => api.post('/contact', data);

export const postEmailList = (data) => api.post('/contact/email/list', data);
