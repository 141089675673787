/*
 * File: SupplyChain.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: October 30, 2023 at 8:17 AM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled, { css } from 'styled-components';

// Components
import { Card, LocaleLink } from '../../../components';

// Constants
import {
	largeBreakpoint, mediumBreakpoint, mobileBreakpoint, xLargeBreakpoint
} from '../../../styles/constants';

// Styles
import { ColorValues } from '../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const HeroSection = styled.div`
	padding: 120px 0px 100px;
	margin: 0px auto;
	width: 100%;
	max-width: 800px;
	text-align: center;

	h1 {
		font-family: 'Unna', serif;
	}
	h4 {
		margin: 20px 0px 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;


export const ContentSection = styled.div`
	padding: 50px 0px 50px 0px;
`;

export const PanelSection = styled.div`
	padding: 0px 0px 40px;
	display: flex;
	flex-direction: column;
	width: auto;
	height: auto;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const ContentPanel = styled.div`
    display: flex;
	flex-direction: column;
	justify-content: center;
	flex: ${({ $wideText }) => ($wideText ? '55%' : '50%')};
	text-align: left;
	margin: 0px auto;
	width: 100%;
	padding: 50px 50px;
	background-color: ${({ $white }) => ($white ? ColorValues.white : 'transparent')};

	* {
		text-align: ${({ $center }) => ($center ? 'center' : 'left')};
	}
	@media only screen and (min-width: ${mobileBreakpoint}em) {
		padding: 4% 6rem;
		width: 88%;
	}
	p {
		margin: 10px 0 0px;
		color: ${({ theme }) => theme.secondaryText};
	}
	h3 {
		font-family: 'Unna', serif;
	}
	h4 {
		margin: 12px 0px 20px;
		color: ${({ theme }) => theme.secondaryText};
	}
`;

export const MediaPanel = styled.div`
	position: relative;
	flex: ${({ $wideText }) => ($wideText ? '45%' : '50%')};
`;

export const MediaPanelImage = styled.img`
	width: 100%;
`;

export const StepGrid = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 30px;
	margin: 50px 0px 0px;

	@media only screen and (min-width: ${mediumBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${largeBreakpoint}em) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media only screen and (min-width: ${xLargeBreakpoint}em) {
		grid-template-columns: repeat(3, 1fr);
	}
`;

export const StepBlock = styled.div`
	display: flex;
	align-items: center;
	gap: 30px;
	flex-direction: column;

	p {
		color: ${({ theme }) => theme.secondaryText};
		margin: 6px 0px 0px;
	}
	* {
		text-align: left;
	}
	@media only screen and (min-width: ${mediumBreakpoint}em) {
		flex-direction: row;
	}
`;

export const StepContent = styled.div`
	text-align: center;

	* {
		text-align: center;
	}
	@media only screen and (min-width: ${mediumBreakpoint}em) {
		text-align: left;

		* {
			text-align: left;
		}
	}
`;

export const StepArrow = styled.div`
	background-color: ${({ theme }) => theme.secondaryBackground};
	color: ${({ theme }) => theme.primaryText};
	border: none;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	flex-shrink: 0;
`;

export const TitleSection = styled.div`
	margin: 0px 0px 30px;
	width: 100%;

	h2 {
		font-family: 'Unna', serif;
		text-align: ${({ $left }) => ($left ? 'left' : 'center')};
	}
	p {
		color: ${({ theme }) => theme.secondaryText};
		text-align: ${({ $left }) => ($left ? 'left' : 'center')};
		margin: 10px 0px 0px;
	}
`;

export const CardContainer = styled.div`
	display: flex;
	gap: 20px;
	margin: 0px 0px 0px;
	flex-direction: column;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const CardLink = styled(LocaleLink)`
	text-decoration: none;
	flex-grow: 1;
    flex-basis: 0;
`;

export const OptionCard = styled(Card)`
	background-color: ${({ theme }) => theme.secondaryBackground};
	text-align: center;
	padding: 30px;

	svg {
		font-size: 2.5rem;
		margin: 0px 0px 15px;
	}
	h4 {
		margin: 0px 0px 10px;
	}
	&:hover {
		transform: scale(1.02);
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px auto 0px;
	gap: 7px;

	svg {
		font-size: 0.85rem;
		margin: 0px;
	}
`;

export const LinkContainer = styled(LocaleLink)`
	display: flex;
	align-items: center;
	gap: 7px;
	text-decoration: none;

	${({ $center }) => ($center ? css`
		justify-content: center;
		margin: 25px auto 0px;
 	` : css`
	 	justify-content: start;
		margin: 25px 0px 0px;
	`)}
	svg {
		font-size: 0.85rem;
		margin: 0px;
	}
	&:hover {
		text-decoration: underline;
	}
`;
