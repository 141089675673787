/*
 * File: ShopSelfCare.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: October 18, 2023 at 8:43 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { LocaleLink, ProductCollection as BaseProductCollection } from '../../../components';

// Constants
import { mobileBreakpoint } from '../../../styles/constants';

// Styles
import { ColorValues } from '../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const TitleSection = styled.div`
	margin: 40px 0px 40px;

	h1 {
		font-family: 'Unna', serif;
	}
`;

export const Description = styled.div`
	text-align: center;
	margin: 10px auto 0px;
	width: 100%;
	max-width: 800px;
	font-weight: 300;
	line-height: 180%;

	* {
		font-weight: 300;
		line-height: 180%;
	}
`;

export const CollectionSelectors = styled.div`
	display: flex;
	width: 100%;
	gap: 24px;
	flex-direction: column;
	flex-shrink: 0;

	@media only screen and (min-width: ${mobileBreakpoint}em) {
		flex-direction: row;
	}
`;

export const CollectionSelector = styled(LocaleLink)`
	position: relative;
	display: flex;
	flex: 50%;
	height: 280px;
	min-height: 280px;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	background-image: ${({ $image }) => `url("${$image}")`};
	background-position: center;
	background-size: cover;
	flex-shrink: 0;

	h4 {
		color: ${ColorValues.white};
		position: relative;
		z-index: 2;
	}
	&:hover {
		transform: scale(1.02);
	}
`;

export const SelectorBackground = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 0.5;
	z-index: 1;
`;

export const ContentSection = styled.div`
	margin: 60px 0px 0px;

	& > h3 {
		font-family: 'Unna', serif;
		text-align: left;
		margin: 0px 0px 10px;
	}
`;

export const ProductCollection = styled(BaseProductCollection)`
    margin: 10px 0px 60px;
`;
