/*
 * File: ShopAllProducts.styles.js
 * Project: 700-rivers-web
 *
 * Created by Brendan Michaelsen on January 31, 2022 at 10:50 PM
 * Copyright © 2022 700 Rivers LLC. All rights reserved.
 *
 * Last Modified: October 14, 2023 at 2:07 PM
 * Modified By: Brendan Michaelsen
 */

/**
 * Imports
 */

// Modules
import styled from 'styled-components';

// Components
import { ProductCollection as BaseProductCollection } from '../../../components';

// Styles
import { ColorValues } from '../../../styles/colors';


/**
 * Styles
 */

export const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: block;
`;

export const TitleSection = styled.div`
	margin: 80px 0px 40px;

	h1 {
		font-family: 'Unna', serif;
	}
`;

export const Description = styled.div`
	text-align: center;
	margin: 10px auto 0px;
	width: 100%;
	max-width: 800px;
	font-weight: 300;
	line-height: 180%;

	* {
		font-weight: 300;
		line-height: 180%;
	}
`;

export const ProductCollection = styled(BaseProductCollection)`
    margin: 80px 0px 60px;
`;

export const BannerContainer = styled.div`
	background-color: ${ColorValues.white};
	width: 100%;
	height: 200px;
	margin-top: 30px;
	overflow: hidden;
`;

export const BannerImage = styled.div`
	background-image: url(${process.env.CDN_URL}/public/covers/rivers-names-cover-1.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 100%;
`;
